import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Icon, Span, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе прокат квадроциклів Adventure Wheels
			</title>
			<meta name={"description"} content={"Вивільни свого внутрішнього дослідника"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе прокат квадроциклів Adventure Wheels"} />
			<meta property={"og:description"} content={"Вивільни свого внутрішнього дослідника"} />
			<meta property={"og:image"} content={"https://fluminater.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fluminater.com/img/1686208.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fluminater.com/img/1686208.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fluminater.com/img/1686208.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fluminater.com/img/1686208.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fluminater.com/img/1686208.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fluminater.com/img/1686208.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
					Про нас
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
					Прокат квадроциклів Adventure Wheels почався з пристрасті до активного відпочинку на природі та бажання поділитися цією пристрастю з іншими. Наші засновники, натхненні власними пригодами на бездоріжжі, вирішили створити місце, де шукачі гострих відчуттів та любителі природи могли б відчути захоплення від їзди на квадроциклах. Зі скромного початку ми перетворилися на шановану службу прокату, відому своєю якісною технікою та винятковим обслуговуванням клієнтів.
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
					Ми віримо, що кожен заслуговує на шанс дослідити красу природи, і наша місія полягає в тому, щоб зробити це можливим. Наша команда прагне забезпечити кожному клієнту безпечний, приємний та незабутній досвід. Незалежно від того, чи ви новачок, чи досвідчений професіонал, ми прагнемо надати вам найкращий досвід оренди квадроциклів.
					</Text>
				</Box>
				<Image
					src="https://fluminater.com/img/3.jpg"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-8">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
				width="100%"
				min-width="100%"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 60px 0px 60px"
				lg-margin="0px 0px 50px 0px"
				sm-padding="0px 25px 0px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
				Наш автопарк
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sans" color="#505257">
				Компанія Adventure Wheels пишається своїм великим парком найсучасніших квадроциклів. Наші транспортні засоби регулярно оновлюються, включаючи найновіші моделі, що гарантує надійну та захоплюючу поїздку кожного разу. Кожен квадроцикл оснащений сучасними системами безпеки та проходить ретельні технічні перевірки, щоб гарантувати вашу безпеку та задоволення.
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
				Ми пропонуємо широкий вибір квадроциклів для різних уподобань та рівнів майстерності. Від потужних, високопродуктивних моделей для любителів адреналіну до простих в управлінні варіантів для початківців - у нас знайдеться щось для кожного. Наша прихильність до якості означає, що ви можете їздити з упевненістю, знаючи, що ви на доглянутому та безпечному транспортному засобі.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Виняткова якість: {" "}
						</Span>
						Наші квадроцикли - найкращі у своєму класі, оснащені найсучаснішими технологіями та засобами безпеки.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Персоналізоване обслуговування: 
						</Span>
						Наш персонал прагне надати вам персоналізований досвід, починаючи від вибору ідеального квадроцикла і закінчуючи експертними порадами щодо маршрутів.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Різноманітні траси: 
						</Span>
						Дослідіть різноманітні маршрути для всіх рівнів підготовки, кожен з яких пропонує унікальні та захоплюючі краєвиди.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				padding="0px 0px 0px 60px"
				margin="0px -10px 0px 0px"
				lg-margin="0px 10px 0px 10px"
				lg-padding="0px 0px 0px 0"
			>
				<Image src="https://fluminater.com/img/4.jpg" display="block" border-radius="25px" />
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="40%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Приєднуйтесь до пригод
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
					Створіть свою історію з прокатом квадроциклів Adventure Wheels. Незалежно від того, чи хочете ви підкорити пересічену місцевість, чи насолодитися спокійною прогулянкою природою, ми тут, щоб зробити вашу пригоду незабутньою. Наша команда прагне надати вам найкращі враження, щоб у вас залишилися спогади на все життя.
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								Телефон
							</Text>
							<Link href="tel:+38098 6727938" color="#000000" text-decoration-line="initial" font="--lead">
							+38098 6727938
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								E-mail
							</Text>
							<Link href="mailto:contact@fluminater.com" color="#000000" text-decoration-line="initial" font="--lead">
							contact@fluminater.com
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});